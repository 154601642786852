<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} GRNs</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} GRNs that match '{{query}}'
      </span>
      <router-link
        v-if="can(uiPermissions.MANUAL_GRN_CREATE)"
        to="/operations/manual-grn/new"
        data-test="btn-new-grn"
        class="btn btn-primary ml-auto">
        New GRN
      </router-link>
    </div>

    <div class="card">
      <div class="table-responsive">
        <table data-test="table-manual-grn" class="table table-hover table-outline table-vcenter text-nowrap card-table">
          <thead>
            <tr>
              <th>GRN Number</th>
              <th>Supplier</th>
              <th>PO Number</th>
              <th>Value</th>
              <th class="text-center">Created by</th>
              <th class="text-center">GRN Creation Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.grn_number">
              <td>{{item.grn_number}}</td>
              <td>{{item.supplier.name}}</td>
              <td>{{item.purchase_order_id}}</td>
              <td>{{item.total}}</td>
              <td class="text-center">{{getFullName(item)}}</td>
              <td class="text-center">{{item.created_at}}</td>
            </tr>
            <tr v-if="items.length === 0">
              <td colspan="5">There are no results matching your criteria.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :currentPage="page"
        :totalPages="meta.totalPages"
        @goToPage="goToPage"></pagination>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {grn} from '@/services';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      withParams: [
        'supplier',
        'createdBy',
      ],
    };
  },
  computed: {
    getFullName() {
      return item => item.creator?.firstName + ' ' + item.creator?.lastName;
    },
  },
  methods: {
    fetchData(page) {
      return grn.getByParameters({

        limit: this.limit,
        page,
        query: this.query,
        with: this.withParams.join(','),
      });
    },
    transformData(result) {
      return {...result, items: result.grn};
    },
  },
};
</script>
